import React from "react"
import { Link } from "gatsby"
import Layout from "../component/Layout"
import "../styles/404.scss"
import SEO from "../component/SEO"

const NotFound = () => {
  return (
    <Layout>
      <SEO title="404" />
      <div className="four">
        <h1>Sorry Page Not Found</h1>
        <p>
          <Link to="/">Head Home</Link>
        </p>
      </div>
    </Layout>
  )
}

export default NotFound
